.wlist-container {
    min-height: 150px;
    background-color: #EAEBF3;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wlist-text {
    color: #000000;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1;
    margin: 0;
}

.wlist-button {
    color: #7169BF !important;
    border: none !important;
    height: 60px;
    width: 100%;
    max-width: 300px;
    letter-spacing: 0.1rem !important;
    font-family: 'Roboto', sans-serif !important;
    font-size: 1.2rem !important;
    font-weight: 800 !important;
    border-radius: 10px !important;
    background: #c7c8cf55 !important;
    box-shadow: 14px -14px 29px #c7c8cf,
    -14px 14px 29px #ffffff !important;
}

.wlist-button:hover {
    color: #6D5EF7 !important;
}
