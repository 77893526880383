.insight-container {
    background-color: transparent;
    min-height: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.insight-img-div {
    width: auto;
    height: 300px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.insight-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.alice-carousel__wrapper {
    padding: 20px;
    background: #EAEBF3;
    border-radius: 20px;
}

.alice-carousel__prev-btn {
    display: none !important;
}

.alice-carousel__next-btn {
    display: none !important;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #F0B12B !important;
}

.insight-line-1 {
    height: 50px;
    width: 100%;
    background-color: #3A0B69;
    position: absolute;
    top: calc(55% - 5px);
    left: 0;
}
