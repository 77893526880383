.roadmap-container {
    min-height: 600px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #EAEBF3;
    background-size: 100% 135%;
    color: #000000;
}

.roadmap-content {
    background-size: 100% auto;
    width: 100%;
    min-height: 450px;
    max-height: 800px;
    object-fit: contain;
    margin-top: 10px;
    margin-bottom: 50px;
}

.roadmap-content h2 {
    position: absolute;
}

.roadmap-text{
    position: absolute!important;
    text-align: left;
    font-size: .9rem;
    line-height: 1.2rem;
}
