.video-container {
    background: #EAEBF3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 150px;
    position: relative;
}

.video-iframe {
    position: relative;
}

.video-iframe .video-player {
    width: 40.9% !important;
    height: 68.8% !important;
    object-fit: contain;
    position: absolute;
    top: 16%;
    left: 29.5%;
}
