.footer-container {
    min-height: 150px;
    background-color: #772784;
    display: flex;
    align-items: center;
}

.footer-grid-container {
    display: flex;
    flex: 1;
    justify-content: space-around;
    align-items: center;
}

.footer-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    height: 100%;
}

.footer-item.privacy a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 15px;
}

.footer-item.privacy a:hover {
    color: #F8C600;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.footer-item.privacy a:last-child {
    margin-bottom: 0;
}

.logo-footer {
    height: 70px;
    width: auto;
}

.footer-item.logos p {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
    letter-spacing: 0.1rem;
}

.footer-logos-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.footer-link-logo {
    height: 30px;
    width: 30px;
    margin: 0;
}

.footer-link-logo:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2);
    box-shadow: 1px 1px 5px #F8C60088;
    border-radius: 50%;
}

.footer-logos-container img {
    height: 30px;
    width: 30px;
}

.footer-item.first {
    margin-bottom: 0;
}

.footer-item.second {
    margin-bottom: 0;
}

.footer-item.third {
    margin-bottom: 0;
}

@media screen and (max-width: 899px) {
    .footer-item.first {
        margin-bottom: 20px;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .footer-item.second {
        margin-bottom: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .footer-item.third {
        margin-bottom: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        max-width: 500px;
    }
}
