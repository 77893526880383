.support-container {
    background-color: #5E5E5E;
    color: white;
    min-height: 350px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
