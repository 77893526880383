.intro-container{
    background-image: url("../../assets/imgs/intro/bg-intro.png");
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    margin-top: 105px;
}

.intro-txt{
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 0.9rem;
    margin: auto;
}

@media screen and (max-width: 899px) {
    .intro-txt{
        font-size: 0.8rem;
        max-width: 90%;
        padding: 10px;
    }

    .intro-container{
        background-size: 100% 100%;
    }
}

.itrade-button{
    text-transform: none!important;
    font-weight: 400!important;
    font-size: 1.3rem!important;
    background-color: #F8C600!important;
    border: 1px solid #F8C600!important;
    box-shadow: none!important;
    border-radius: 10px!important;
    padding: 5px 25px!important;
}
