.team-container {
    background: url("../../assets/imgs/team/bg-team.png") no-repeat center;
    background-size: 100% 135%;
    min-height: 500px;
    color: #420B72;
}

.team-img {
    width: auto;
    height: 320px;
}
