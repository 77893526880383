@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Italic.ttf');
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Thin.ttf');
    font-weight: 100;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-Black.ttf');
    font-weight: 900;
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
}

body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #5E1882;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    overflow-x: hidden;
    min-height: 100vh;
    /*background: linear-gradient(to bottom, #2896FF 0%, #2896FF 25%, #A0DCFF 100%);*/
    background-color: #190C40;
    padding: 0 !important;
}
