.comms-container{
    min-height: 50px;
    background: rgb(119,39,132);
    background: linear-gradient(#430B73, #120D3B);
    border-radius: 20px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.comms-nft{
    text-align: right!important;
}

.comms-headline{
    color: #FFFFFF;
    font-weight: 700;
    font-size: 2.5rem;
    text-align: center;
    line-height: 1;
    margin-bottom: 1rem;
}

.comms-text{
    color: #FFFFFF;
    font-weight: 400;
    font-size: .8rem;
    text-align: left;
    line-height: 1;
    margin-top: 0.1rem;
    text-indent: 0.0rem;
}

.comms-timer-container{
    margin-top: 20px;
    margin-bottom: 20px;
}

.comms-timer-num{
    font-size: 1.5rem;
    font-weight: 800;
}

.comms-timer-sqr{
    height: 20%;
    width: auto;
    min-width: 70px;
    min-height: 70px;
    background: linear-gradient(#FF00FF, #7A2784 80%);
    border-radius: 25%;
    color: #FFFFFF;
    text-align: center;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.comms-timer-text{
    font-family: 'Roboto', sans-serif;
    font-size: .7rem;
    font-weight: 500;
    text-align: center;
    margin-top: -20px;
    max-width: 100%;
    overflow: hidden;
}

.cmint-container{
    text-align: center;
}

.cmint-button{
    font-weight: 600 !important;
    font-size: 1.1rem !important;
    background-color: #6758E8 !important;
    border-radius: 10px !important;
    padding: 5px 25px !important;
    margin-top: 30px !important;
    width: 200px;
    max-width: 100%;
    height: 60px;
    letter-spacing: 1px !important;
}

@media screen and (max-width: 899px) {
    .comms-timer-sqr {
        min-width: auto;
        min-height: auto;
    }
}
